var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VRow',{staticClass:"mt-4"},[_c('VCol',{attrs:{"cols":""}},[_c('VCard',[_c('VCardTitle',[_c('div',{staticClass:"d-flex flex-row"},[_vm._v(" All Roles "),_c('VEditDialog',{attrs:{"large":""},on:{"save":function($event){return _vm.endAddRoles(true)},"cancel":function($event){return _vm.endAddRoles(false)},"close":function($event){return _vm.endAddRoles(false)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('VTextField',{attrs:{"label":"Role Name","single-line":"","loading":_vm.newRole.loading,"rules":[_vm.rules.required, _vm.rules.min3, _vm.rules.max100],"counter":""},model:{value:(_vm.newRole.name),callback:function ($$v) {_vm.$set(_vm.newRole, "name", $$v)},expression:"newRole.name"}})]},proxy:true}])},[_c('div',{staticClass:"d-flex"},[_c('VBtn',{staticClass:"ml-2",attrs:{"fab":"","x-small":"","color":"primary","loading":_vm.newRole.loading}},[_c('VIcon',{attrs:{"small":""}},[_vm._v("fa fa-plus")])],1)],1)]),_c('VSnackbar',{attrs:{"bottom":"","right":"","timeout":"3000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('VBtn',_vm._b({attrs:{"color":"blue","text":""},on:{"click":function($event){_vm.newRole.snack.active = false}}},'VBtn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.newRole.snack.active),callback:function ($$v) {_vm.$set(_vm.newRole.snack, "active", $$v)},expression:"newRole.snack.active"}},[_vm._v(" "+_vm._s(_vm.newRole.snack.message)+" ")])],1),_c('VSpacer'),_c('VTextField',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","placeholder":"longthanh","prepend-icon":"fa-search","hint":"Search by email, name or role, ..."},model:{value:(_vm.queryRole),callback:function ($$v) {_vm.queryRole=$$v},expression:"queryRole"}})],1),_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.roles,"multi-sort":"","loading":_vm.tableLoading,"search":_vm.queryRole,"show-select":"","single-select":"","item-key":"name"},on:{"input":_vm.fetchImplicitPolicies},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.name != 'superadmin')?_c('div',{staticClass:"d-flex"},[_c('VEditDialog',{attrs:{"large":"","return-value":item.name,"cancel-text":"No","save-text":"Yes"},on:{"update:returnValue":function($event){return _vm.$set(item, "name", $event)},"update:return-value":function($event){return _vm.$set(item, "name", $event)},"save":function($event){return _vm.endDeleteRole(item, true)},"cancel":function($event){return _vm.endDeleteRole(item, false)},"close":function($event){return _vm.endDeleteRole(item, false)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('div',{staticClass:"d-flex pt-3"},[_c('strong',[_vm._v("Are you sure you want to delete this item?")])])]},proxy:true}],null,true)},[_c('VBtn',{staticClass:"px-0",attrs:{"fab":"","x-small":"","color":"error"}},[_c('VIcon',{attrs:{"fab":"","x-small":""}},[_vm._v("fa fa-trash")])],1)],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}})],1)],1),(_vm.allRoles.includes(_vm.selectedRole))?_c('VCol',{attrs:{"cols":"8"}},[_c('VRow',[(_vm.selectedRole)?_c('VCol',{attrs:{"cols":"6"}},[_c('VCard',{attrs:{"loading":_vm.loadingImplicit}},[_c('VCardTitle',[_c('h5',{staticClass:"mr-8"},[_vm._v("Current Policies")]),_c('VTextField',{attrs:{"append-icon":"fa-search","label":"Search"},model:{value:(_vm.queryImplicit),callback:function ($$v) {_vm.queryImplicit=$$v},expression:"queryImplicit"}})],1),_c('VVirtualScroll',{attrs:{"items":_vm.querriedImplicitPolicy,"height":"400","item-height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('VListItem',{key:((item.Method) + ":" + (item.Path))},[_c('VListItemAction',[_c('VBtn',{attrs:{"fab":"","x-small":"","depressed":"","color":"error","disabled":item.Role !== _vm.selectedRole || (item.Role == 'superadmin' && !_vm.me.roles.includes('superadmin')),"loading":item.__loading},on:{"click":function($event){return _vm.doDeleteUserPolicy(item)}}},[_c('VIcon',{attrs:{"x-small":""}},[_vm._v("fa-minus")])],1)],1),_c('VListItemContent',[_c('VListItemTitle',[_c('strong',{class:_vm.getMethodColor(item.Method)},[_vm._v(_vm._s(item.Method))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.Path))])])],1)],1)]}}],null,false,434587372)})],1)],1):_vm._e(),_c('VCol',{attrs:{"cols":"6"}},[_c('VCard',{attrs:{"loading":_vm.policyLoading}},[_c('VCardTitle',[_c('h5',{staticClass:"mr-8"},[_vm._v("Available Policies")]),_c('VTextField',{attrs:{"append-icon":"fa-search","label":"Search"},model:{value:(_vm.queryPolicy),callback:function ($$v) {_vm.queryPolicy=$$v},expression:"queryPolicy"}})],1),_c('VVirtualScroll',{attrs:{"items":_vm.querriedPolicies,"height":"400","item-height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('VListItem',{key:((item.Method) + ":" + (item.Path))},[_c('VListItemAction',[_c('VBtn',{attrs:{"fab":"","x-small":"","depressed":"","color":"primary","disabled":_vm.selectedRole == 'superadmin' && !_vm.me.roles.includes('superadmin'),"loading":item.__loading},on:{"click":function($event){return _vm.doAddUserPolicy(item)}}},[_c('VIcon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)],1),_c('VListItemContent',[_c('VListItemTitle',[_c('strong',{class:_vm.getMethodColor(item.Method)},[_vm._v(" "+_vm._s(item.Method)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.Path))])])],1)],1)]}}],null,false,1618453206)})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }