<template>
  <VRow class="mt-4">
    <VCol cols="">
      <VCard>
        <VCardTitle>
          <div class="d-flex flex-row">
            All Roles
            <VEditDialog
              large
              @save="endAddRoles(true)"
              @cancel="endAddRoles(false)"
              @close="endAddRoles(false)"

            >
              <div class="d-flex">
                <VBtn
                  class="ml-2"
                  fab
                  x-small
                  color="primary"
                  :loading="newRole.loading"
                >
                  <VIcon small class="">fa fa-plus</VIcon>
                </VBtn>
              </div>
              <template v-slot:input>
                <VTextField
                  v-model="newRole.name"
                  label="Role Name"
                  single-line
                  :loading="newRole.loading"
                  :rules="[rules.required, rules.min3, rules.max100]"
                  counter
                />
              </template>
            </VEditDialog>
            <VSnackbar
              v-model="newRole.snack.active"
              bottom
              right
              timeout="3000"
            >
              {{ newRole.snack.message }}
              <template v-slot:action="{ attrs }">
                <VBtn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="newRole.snack.active = false"
                >
                  Close
                </VBtn>
              </template>
            </VSnackbar>
          </div>
          <VSpacer />
          <VTextField
            v-model="queryRole"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            placeholder="longthanh"
            prepend-icon="fa-search"
            hint="Search by email, name or role, ..."
          />
        </VCardTitle>
        <VDataTable
          v-model="selectedRoles"
          @input="fetchImplicitPolicies"
          :headers="headers"
          :items="roles"
          multi-sort
          :loading="tableLoading"
          :search="queryRole"
          show-select
          single-select
          item-key="name"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex" v-if="item.name != 'superadmin'">
              <VEditDialog
                large
                :return-value.sync="item.name"
                @save="endDeleteRole(item, true)"
                @cancel="endDeleteRole(item, false)"
                @close="endDeleteRole(item, false)"
                cancel-text="No"
                save-text="Yes"
              >
                <VBtn class="px-0" fab x-small color="error">
                  <VIcon fab x-small class="">fa fa-trash</VIcon>
                </VBtn>
                <template v-slot:input>
                  <div class="d-flex pt-3">
                    <strong>Are you sure you want to delete this item?</strong>
                  </div>
                </template>
              </VEditDialog>
            </div>
          </template>
        </VDataTable>
      </VCard>
    </VCol>
    <VCol cols="8" v-if="allRoles.includes(selectedRole)">
      <VRow>
        <VCol cols="6" v-if="selectedRole">
          <VCard :loading="loadingImplicit">
            <VCardTitle>
              <h5 class="mr-8">Current Policies</h5>
              <VTextField
                append-icon="fa-search"
                label="Search"
                v-model="queryImplicit"
              />
            </VCardTitle>
            <VVirtualScroll
              :items="querriedImplicitPolicy"
              height="400"
              item-height="56"
            >
              <template #default="{ item }">
                <VListItem :key="`${item.Method}:${item.Path}`">
                  <VListItemAction>
                    <VBtn
                      fab
                      x-small
                      depressed
                      color="error"
                      :disabled="item.Role !== selectedRole || (item.Role == 'superadmin' && !me.roles.includes('superadmin'))"
                      @click="doDeleteUserPolicy(item)"
                      :loading="item.__loading"
                    >
                      <VIcon x-small>fa-minus</VIcon>
                    </VBtn>
                  </VListItemAction>
                  <VListItemContent>
                    <VListItemTitle>
                      <strong :class="getMethodColor(item.Method)">{{
                        item.Method
                      }}</strong>
                      <span class="ml-2">{{ item.Path }}</span>
                    </VListItemTitle>
                  </VListItemContent>
                </VListItem>
              </template>
            </VVirtualScroll>
          </VCard>
        </VCol>
        <VCol cols="6">
          <VCard :loading="policyLoading">
            <VCardTitle>
              <h5 class="mr-8">Available Policies</h5>
              <VTextField
                append-icon="fa-search"
                label="Search"
                v-model="queryPolicy"
              />
            </VCardTitle>
            <VVirtualScroll
              :items="querriedPolicies"
              height="400"
              item-height="56"
            >
              <template #default="{ item }">
                <VListItem :key="`${item.Method}:${item.Path}`">
                  <VListItemAction>
                    <VBtn
                      fab
                      x-small
                      depressed
                      color="primary"
                      :disabled="selectedRole == 'superadmin' && !me.roles.includes('superadmin')"
                      @click="doAddUserPolicy(item)"
                      :loading="item.__loading"
                    >
                      <VIcon x-small>fa-plus</VIcon>
                    </VBtn>
                  </VListItemAction>
                  <VListItemContent>
                    <VListItemTitle>
                      <strong :class="getMethodColor(item.Method)">
                        {{ item.Method }}
                      </strong>
                      <span class="ml-2">{{ item.Path }}</span>
                    </VListItemTitle>
                  </VListItemContent>
                </VListItem>
              </template>
            </VVirtualScroll>
          </VCard>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import SlideInPanel from "@/components/essenstials/SlideInPanel.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import errorHandle from "@/mixins/errorHandle";
import { getMethodColor } from "@/utils/vuetify";

export default {
  name: "ManageRole",
  components: { SlideInPanel },
  mixins: [errorHandle()],
  data: () => ({
    panelActive: false,
    tableLoading: false,
    roles: [],
    queryRole: "",
    selectedRoles: [],

    policyLoading: false,
    policies: [],
    queryPolicy: "",

    loadingImplicit: false,
    implicit: [],
    queryImplicit: "",

    newRole: {
      loading: false,
      name: "",
      snack: {
        active: false,
        message: "",
      },
    },
  }),
  computed: {
    ...mapGetters({
      me: "authUser",
    }),
    ...mapState("su", {
      allRoles: (state) => state.role.list,
      roleLoading: (state) => state.role.loading,
      allPolicies: (state) => state.policy.list,
      // policyLoading: (state) => state.policy.loading,
    }),
    headers() {
      return [
        { text: "Name", value: "name", sortable: true },
        { text: "Actions", value: "actions", sortable: false },
      ];
    },
    rules() {
      return {
        required: (value) => !!value || "Required",
        min3: (value) => value.length >= 3 || "Minimum 3 characters",
        max100: (value) => value.length <= 100 || "Maximum 100 characters",
      };
    },
    querriedPolicies() {
      const q = (this.queryPolicy || "").toLowerCase();
      const implicit = this.implicit || [];
      return this.policies
        .filter((x) => {
          if (!this.selectedRoles || !this.selectedRoles.length) {
            return true;
          }
          return (
            implicit.findIndex(
              (p) =>
                p.Method == x.Method &&
                p.Path == x.Path &&
                p.Role == this.selectedRole
            ) < 0
          );
        })
        .filter((x) => {
          const method = (x.Method || "").toLowerCase();
          const path = (x.Path || "").toLowerCase();
          return method.indexOf(q) > -1 || path.indexOf(q) > -1;
        });
    },
    querriedImplicitPolicy() {
      const q = (this.queryImplicit || "").toLowerCase();
      return (this.implicit || []).filter((x) => {
        const method = (x.Method || "").toLowerCase();
        const path = (x.Path || "").toLowerCase();
        return method.indexOf(q) > -1 || path.indexOf(q) > -1;
      });
    },
    selectedRole() {
      if (!this.selectedRoles || !this.selectedRoles.length) {
        return null;
      }
      return this.selectedRoles[0].name;
    },
  },
  mounted() {
    this.fetchRoles();
    this.fetchPolicies();
  },
  watch: {
    allRoles() {
      this.fetchRoles();
    },
  },
  methods: {
    ...mapMutations({
      OpenError: "OpenError",
    }),
    ...mapActions("su", {
      addRole: "addRole",
      deleteRole: "deleteRole",
      getPolicies: "fetchPolicies",
      getImplicitPolicies: "fetchImplicitPolicies",
      addUserPolicy: "addUserPolicy",
      deleteUserPolicy: "deleteUserPolicy",
    }),
    getMethodColor,
    fetchRoles() {
      this.roles = this.allRoles.map((x) => ({
        name: x,
        __loading: false,
      }));
    },
    fetchPolicies() {
      this.policyLoading = true;
      this.getPolicies()
        .then((res) => {
          this.policies = this.allPolicies.map((x) => ({
            ...x,
            __loading: false,
          }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.policyLoading = false;
        });
    },
    fetchImplicitPolicies(items) {
      console.log(items);
      if (!items || !items.length) {
        return;
      }
      const email = items[0].name;
      this.loadingImplicit = true;
      this.getImplicitPolicies(email)
        .then((data) => {
          this.implicit = data.map((x) => ({
            ...x,
            __loading: false,
          }));
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          this.loadingImplicit = false;
        });
    },
    resetNewRole() {
      this.newRole.loading = false;
      this.newRole.name = "";
      // this.newRole.snack.active = false;
      // this.newRole.snack.message = "";
    },
    endAddRoles(submit) {
      if (!submit) {
        this.resetNewRole();
        return;
      }
      const role = (this.newRole.name || "").trim();
      if (role == "") {
        this.newRole.snack.active = true;
        this.newRole.snack.message = "Role name must not be empty";
        return;
      }
      if (role.length < 3 || role.length > 100) {
        this.newRole.snack.active = true;
        this.newRole.snack.message = "Role name characters must be in range [3, 100]";
        return;
      }
      this.newRole.loading = true;
      this.addRole({
        email: null,
        role: role,
      })
        .then((res) => {
          this.resetNewRole();
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          this.newRole.loading = false;
        });
    },

    endDeleteRole(item, submit) {
      if (!submit) {
        return;
      }
      item.__loading = true;
      this.deleteRole({
        email: null,
        role: item.name,
      })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          item.__loading = false;
        });
    },
    doDeleteRole(item) {},

    doAddUserPolicy(item) {
      item.__loading = true;
      this.addUserPolicy({
        email: this.selectedRole,
        policy: item,
      })
        .then(() => {
          item.__loading = false;
          this.implicit.push({
            Domain: "gempages",
            Role: this.selectedRole,
            Method: item.Method,
            Path: item.Path,
            __loading: false,
          });
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          item.__loading = false;
        });
    },
    doDeleteUserPolicy(item) {
      item.__loading = true;
      this.deleteUserPolicy({
        email: this.selectedRole,
        policy: item,
      })
        .then(() => {
          item.__loading = false;
          const idx = this.implicit.findIndex(
            (x) =>
              x.Method == item.Method &&
              x.Role == this.selectedRole &&
              x.Path == item.Path
          );
          if (idx > -1) {
            this.implicit.splice(idx, 1);
          }
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          item.__loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
